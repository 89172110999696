import React from "react"
import "./AiSoftwareDevlopment.css"
const AiSoftwareDevlopment = () => {
  const industries = [
    {
      title: "Healthcare",
      description:
        "AI solutions for patient diagnosis, treatment recommendations, and medical image analysis.",
      icon: (
        <i
          className="icon-ai-ml-development-services-healthcare"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Finance",
      description:
        "AI-powered fraud detection, risk assessment models, and predictive analytics for investment strategies.",
      icon: (
        <i
          className="icon-ai-ml-development-services-finance"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Retail",
      description:
        "AI-driven recommendation engines, customer behavior analytics, and inventory optimization.",
      icon: (
        <i
          className="icon-ai-ml-development-services-retail"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Manufacturing",
      description:
        "Predictive maintenance, intelligent supply chain management, and automated quality control using AI.",
      icon: (
        <i
          className="icon-ai-ml-development-services-manufacturing"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Automotive",
      description:
        "AI for autonomous driving systems, predictive maintenance, and advanced driver assistance systems (ADAS).",
      icon: (
        <i
          className="icon-machine-learning-development-automotive"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "E-commerce",
      description:
        "Personalization engines, AI chatbots for customer service, and demand forecasting for smarter inventory management.",
      icon: (
        <i
          className="icon-ai-ml-development-services-e-commerce"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
  ]

  return (
    <div
      className="min-h-screen flex items-center justify-center text-white bg-cover bg-center relative"
      style={{
        backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2025/01/businessman-search-new-opportunities-international-business-typing-laptop-background-world-map-hologram-scaled.jpg")`,
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="relative z-10 p-6 md:p-16 rounded-lg w-full">
        {/* Title */}
        <h1 className="text-3xl md:text-5xl font-bold my-4 md:mb-6 text-center text-white">
          AI Solutions Across Industries
        </h1>

        {/* Subtitle */}
        <p className="text-lg md:text-xl text-gray-300 text-center  mx-auto mb-8">
          AI is transforming industries in profound ways. Here are some of the
          sectors we specialize in
        </p>

        {/* Industry Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full mx-auto gap-4">
          {industries.map((industry, index) => (
            <div className="feature-tag">
              <div className="flex flex-col items-start h-full">
                <div
                  className="w-20 h-20 md:w-24 md:h-24 lg:w-26 lg:h-26 rounded-2xl text-white flex items-center justify-center mb-6 text-2xl md:text-4xl lg:text-6xl"
                  style={{
                    background: "rgba(255, 255, 255, 0.10)",
                  }}
                >
                  {industry.icon}
                </div>
                <h3 className="text-xl lg:text-2xl font-bold text-white mb-4">
                  {industry.title}
                </h3>
                <div className="text-left font-normal text-lg text-white overflow-hidden">
                  {industry.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AiSoftwareDevlopment
